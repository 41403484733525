<template>
  <div class="online-detail-dialog">
    <w-dialog
      ref="dialogRef"
      title="上线配置"
      width="60%"
      top="15vh"
      @wConfirm="handleSure"
    >
      <div class="checkbox-container">
        <p>请选择上线景区</p>
        <el-checkbox-group v-model="formData.scenic">
          <el-checkbox
            :label="item.s_id"
            v-for="(item, index) in scenicData"
            :key="index"
            >{{ item.s_name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="checkbox-container">
        <p>请选择上线酒店</p>
        <el-checkbox-group v-model="formData.hotel">
          <el-checkbox
            :label="item.h_id"
            v-for="(item, index) in hotelData"
            :key="index"
            >{{ item.h_name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="checkbox-container">
        <p>请选择上线餐厅</p>
        <el-checkbox-group v-model="formData.dinning">
          <el-checkbox
            :label="item.dr_id"
            v-for="(item, index) in dinnerData"
            :key="index"
            >{{ item.dr_name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>

      <!-- <p>请选择上线酒店</p>
      <p>请选择上线餐厅</p> -->
    </w-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { BasicApi } from "@/plugins/api.js";
// import { ElMessage } from "element-plus";
export default {
  emits: ["submit"],
  setup(props, { emit }) {
    const dialogRef = ref(null);
    const scenicData = ref([]);
    const hotelData = ref([]);
    const dinnerData = ref([]);

    function getAllScenicData() {
      BasicApi.getAllScenicData().then((res) => {
        scenicData.value = res.Data;
      });
    }
    function getAllHotelData() {
      BasicApi.getAllHotelData().then((res) => {
        hotelData.value = res.Data;
      });
    }
    function getAllDinnerData() {
      BasicApi.getAllDinnerData().then((res) => {
        dinnerData.value = res.Data;
      });
    }
    const formData = ref({
      scenic: [],
      hotel: [],
      dinning: [],
    });
    const id = ref(null);
    function openDialog(rowData) {
      id.value = rowData.mp_id;
      getAllScenicData();
      getAllHotelData();
      getAllDinnerData();
      const selestHotel = [];
      const selestDinner = [];
      const selestScenic = [];

      rowData.miniSourceHotel &&
        rowData.miniSourceHotel.forEach((el) => {
          selestHotel.push(el.mss_hid);
        });
      rowData.miniSourceDinning &&
        rowData.miniSourceDinning.forEach((el) => {
          selestDinner.push(el.mss_dr_id);
        });
      rowData.miniSourceScenic &&
        rowData.miniSourceScenic.forEach((el) => {
          selestScenic.push(el.mss_sid);
        });

      formData.value = {
        scenic: selestScenic,
        hotel: selestHotel,
        dinning: selestDinner,
      };
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    function handleSure() {
      dialogRef.value.isLoading = true;
      const lastData = {
        mp_id: id.value,
        ...formData.value,
      };
      emit("submit", lastData);
    }
    return {
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      formData,
      scenicData,
      hotelData,
      dinnerData,
      closeDialogLoading
    };
  },
};
</script>

<style lang="scss">
.online-detail-dialog {
  p {
    color: var(--text-third-color);
  }
  .checkbox-container {
    border-bottom: 1px solid var(--search-bg-color);
    margin-bottom: 20px;
    .el-checkbox-group {
      padding: 8px 0;
    }
  }
  .el-dialog__body {
    .net-config-content {
      height: 400px;
      overflow-y: auto;
    }
  }
}
</style>
