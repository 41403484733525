<template>
  <div class="minimanage main-cnt">
    <div class="title">小程序列表</div>
    <div class="content">
      <common-table
        ref="miniTable"
        tableHeight="calc(100vh - 270px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="BasicApi.miniList"
        :columns="tableColumns"
        @config="openConfigDialog"
        @showAlbum="openOnlineDialog"
      >
      </common-table>
    </div>
    <OnlineDialog @submit="handleOnline" ref="onlineDialogRef"></OnlineDialog>
    <ConfigDialog ref="configDialogRef"></ConfigDialog>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";

import OnlineDialog from "../components/OnlineDialog.vue";
import ConfigDialog from "../components/ConfigDialog.vue";
import { ElMessage } from "element-plus";
import { BasicApi } from "@/plugins/api.js";

/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "mp_id",
    label: "序号",
    minWidth: 80,
    color: "--text-third-color",
  },
  {
    prop: "mp_name",
    label: "小程序名称",
    minWidth: 120,
    color: "--text-color",
  },
  {
    type: "block",
    prop: "number",
    label: "上线明细",
    minWidth: 80,
    token: "mp_yEBrDBqWsN4J6ndhp11TAZuBDX",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "配置",
        action: "config",
        token: "mp_FEo2p5y0Xd95XRp4p4LpcyyYqw",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 入口表格对象 */
const miniTable = ref(null);
const onlineDialogRef = ref(null);
/** 点击 上线明细 */
const openOnlineDialog = (row) => {
  onlineDialogRef.value.openDialog(row);
};
function handleOnline(data) {
  BasicApi.miniDetailSet(data).then((res) => {
    onlineDialogRef.value.closeDialogLoading();
    if (res.Code === 200) {
      onlineDialogRef.value.closeDialog();
      miniTable.value.tableLoad();
      ElMessage.success("操作成功");
    } else {
      let msg = res.Message ? res.Message : "操作失败";
      ElMessage.error(msg);
    }
  });
}
/** 配置 弹框 */
const configDialogRef = ref(null);
/** 表格 配置 */
const openConfigDialog = (row) => {
  configDialogRef.value.openDialog(row);
};

onMounted(() => {
  // 获取闸机表格数据
  miniTable.value.tableLoad();
});
</script>

<style lang="scss">
.minimanage {
  font-family: "Source Han Sans CN";
  .content {
    padding: 20px;
  }
  .config-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 5px 15px 80px;
        .add-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 25px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-select {
                width: 100%;
                .el-input {
                  width: 100%;
                }
              }
              .el-date-editor {
                width: 100%;
                .el-range-input {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
