<template>
  <div class="config-detail-dialog">
    <w-dialog
      ref="dialogRef"
      title="配置小程序"
      width="70%"
      top="6vh"
      :hideFooter="true"
    >
      <main class="config-detail-main">
        <el-tabs
          v-model="activeName"
          type="card"
          class="demo-tabs"
          @tab-click="handleClick"
        >
          <el-tab-pane label="首页" name="1"></el-tab-pane>
          <el-tab-pane label="餐厅" name="2"></el-tab-pane>
          <el-tab-pane label="景点" name="3"></el-tab-pane>
        </el-tabs>
        <div class="config-imgs">
          <div class="config-imgs-left">
            <div class="banner">
              <p>顶部banner栏</p>
              <template v-for="(el, index) in bannerArr" :key="index">
                <div class="config-img-item">
                  <template v-if="activeName == 1">
                    <el-cascader
                      @change="handleSelect($event, el, 1)"
                      :options="cascaderOptions"
                      :show-all-levels="false"
                      v-model="el.mpc_third_id"
                      placeholder="请选择关联活动或商品"
                    />
                  </template>
                  <template v-else>
                    <el-select
                      v-model="el.mpc_third_id"
                      :key="index"
                      placeholder="请选择关联活动或商品"
                      @change="handleSelect($event, el, 1)"
                    >
                      <el-option
                        v-for="(el2, index2) in selectOptions"
                        :label="el2.label"
                        :value="el2.value"
                        :key="index2"
                      ></el-option> </el-select
                  ></template>
                  <img-upload
                    :class="index == 0 ? 'first-img-item' : 'other-img-item'"
                    suggestText=""
                    :noReplaceImg="false"
                    :fileList="el.file_url ? [{ file_url: el.file_url }] : []"
                    @uploadFile="uploadFile($event, el, 1)"
                    uploadTitle="banner图片"
                  ></img-upload>

                  <span v-if="index == 0" class="config-img-tips"
                    >建议尺寸702*292px，不超过2MB</span
                  >

                  <el-button class="delete-btn" @click="onDeleteBtn(el)" v-if="el.mpc_id">删除</el-button>
                </div>
              </template>
            </div>
            <div class="ticket" v-if="activeName == 1">
              <p>门票栏</p>
              <template v-for="(el, index) in ticketArr" :key="index">
                <div class="config-img-item">
                  <div>
                    <el-select
                      v-model="el.mpc_third_id"
                      :key="index"
                      placeholder="请选择关联门票"
                      @change="handleSelect($event, el, 3)"
                    >
                      <el-option
                        v-for="(el2, index2) in selectOptions"
                        :label="el2.label"
                        :value="el2.value"
                        :key="index2"
                      ></el-option>
                    </el-select>
                    <div style="display: flex;margin: 5px 0 20px 0;">
                      <div style="margin-right: 10px;width: 150px;">
                        <el-input v-model="el.mpc_score" type="number" placeholder="输入评分" oninput="if(value<0)value=0" @blur="inputBoxBlur($event, el, 3)" >
                          <template #append>分</template>
                        </el-input>
                      </div>
                      <div style="width: 150px;">
                        <el-input v-model="el.mpc_sales_num" type="number" placeholder="输入月售" oninput="if(value<0)value=0" @blur="inputBoxBlur($event, el, 3)" >
                          <template #append>份</template>
                        </el-input>
                      </div>
                    </div>
                  </div>
                  <img-upload
                    :class="index == 0 ? 'tickets' : 'other-img-item'"
                    suggestText=""
                    :noReplaceImg="false"
                    :fileList="el.file_url ? [{ file_url: el.file_url }] : []"
                    @uploadFile="uploadFile($event, el, 3)"
                    uploadTitle="门票图片"
                  ></img-upload>
                  <span v-if="index == 0" class="config-img-tips"
                    >建议尺寸194*174px，不超过2MB</span
                  >

                  <el-button class="delete-btn" @click="onDeleteBtn(el)" v-if="el.mpc_id">删除</el-button>
                </div>
              </template>
            </div>
          </div>
          <div class="config-imgs-right">
            <div class="activity">
              <p>活动栏</p>
              <template v-for="(el, index) in activityArr" :key="index">
                <div class="config-img-item">
                  <el-select
                    v-model="el.mpc_third_id"
                    :key="index"
                    @change="handleSelect($event, el, 2)"
                    placeholder="请选择关联活动"
                  >
                    <el-option
                      v-for="(el2, index2) in allActiveData"
                      :label="el2.label"
                      :value="el2.value"
                      :key="index2"
                      :disabled="el2.isDisabled"
                    ></el-option>
                  </el-select>
                  <img-upload
                    :class="index == 0 ? 'first-img-item' : 'other-img-item'"
                    suggestText=""
                    :noReplaceImg="false"
                    :fileList="el.file_url ? [{ file_url: el.file_url }] : []"
                    @uploadFile="uploadFile($event, el, 2)"
                    uploadTitle="活动图片"
                  ></img-upload>
                  <span v-if="index == 0" class="config-img-tips"
                    >建议尺寸342*116px，不超过2MB</span
                  >

                  <el-button class="delete-btn" @click="onDeleteBtn(el)" v-if="el.mpc_id">删除</el-button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </main>
    </w-dialog>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { BasicApi } from "@/plugins/api.js";
import imgUpload from "@/components/img-upload/img-upload.vue";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  emits: ["submit"],
  components: {
    imgUpload,
  },
  setup() {
    const store = useStore();
    const dialogRef = ref(null);
    const activeName = ref(null);
    const id = ref(null);
    const allScenicData = ref([]);
    function getAllScenicData() {
      allScenicData.value = [];
      BasicApi.getAllScenicData().then((res) => {
        res.Data.length &&
          res.Data.forEach((el) => {
            allScenicData.value.push({
              label: el.s_name,
              value: el.s_id,
            });
          });
      });
    }
    const allHotelData = ref([]);
    function getAllHotelData() {
      allHotelData.value = [];
      BasicApi.getAllHotelData().then((res) => {
        res.Data.length &&
          res.Data.forEach((el) => {
            allHotelData.value.push({
              label: el.h_name,
              value: el.h_id,
            });
          });
      });
    }
    const allDinnerData = ref([]);
    function getAllDinnerData() {
      allDinnerData.value = [];
      BasicApi.getAllDinnerData().then((res) => {
        res.Data.length &&
          res.Data.forEach((el) => {
            allDinnerData.value.push({
              label: el.dr_name,
              value: el.dr_id,
            });
          });
      });
    }
    const allActiveData = ref([]);
    function getAllActiveData() {
      allActiveData.value = [];
      BasicApi.getAllActiveData({ req_type: 1 }).then((res) => {
        res.Data &&
          res.Data.length &&
          res.Data.forEach((el) => {
            allActiveData.value.push({
              label: el.ma_name,
              value: el.ma_id,
              isDisabled: !el.is_enable,
            });
          });
      });
    }

    const allTicketData = ref([]);
    function getAllTicketData() {
      allTicketData.value = [];
      BasicApi.getAllTicketData().then((res) => {
        res.Data.length &&
          res.Data.forEach((el) => {
            allTicketData.value.push({
              label: el.st_name,
              value: el.st_id,
            });
          });
      });
    }

    const selectOptions = computed(() => {
      let result = [];
      if (activeName.value == "1") {
        result = allTicketData.value;
      } else if (activeName.value == "2") {
        result = allDinnerData.value;
      } else if (activeName.value == "3") {
        result = allScenicData.value;
      }
      return result;
    });
    const cascaderOptions = computed(() => {
      const basicOptions = [
        {
          label: "酒店",
          value: 1,
          children: allHotelData.value,
        },
        {
          label: "景区",
          value: 2,
          children: allScenicData.value,
        },
        {
          label: "餐厅",
          value: 3,
          children: allDinnerData.value,
        },
        {
          label: "活动",
          value: 5,
          children: allActiveData.value,
        },
        {
          label: "门票",
          value: 6,
          children: allTicketData.value,
        },
      ];
      return basicOptions;
    });

    const bannerArr = ref([]);
    const ticketArr = ref([]);
    const activityArr = ref([]);
    const configObj = ref({
      file_url: null,
      mpc_id: null,
      mpc_img: null,
      mpc_third_id: null,
      mpc_third_type: null,
      third_name: null,
    });
    function getDetail(id, type) {
      bannerArr.value = [];
      ticketArr.value = [];
      activityArr.value = [];
      BasicApi.gitMiniConfig({ mp_id: id, mpc_type: type }).then((res) => {
        store.dispatch("getQiniuData");
        for (let i = 0; i < 4; i++) {
          if (res.Data.banner.length && res.Data.banner[i]) {
            if (activeName.value == "1") {
              res.Data.banner[i]["mpc_third_id"] = [
                res.Data.banner[i]["mpc_third_type"],
                res.Data.banner[i]["mpc_third_id"],
              ];
            }
            bannerArr.value.push(res.Data.banner[i]);
          } else {
            const lastObj = JSON.parse(JSON.stringify(configObj.value));
            bannerArr.value.push(lastObj);
          }
          if (res.Data.activity.length && res.Data.activity[i]) {
            activityArr.value.push(res.Data.activity[i]);
          } else {
            const lastObj = JSON.parse(JSON.stringify(configObj.value));
            activityArr.value.push(lastObj);
          }
        }
        for (let i = 0; i < 6; i++) {
          if (res.Data.ticket.length && res.Data.ticket[i]) {
            ticketArr.value.push(res.Data.ticket[i]);
          } else {
            const lastObj = JSON.parse(JSON.stringify(configObj.value));
            ticketArr.value.push(lastObj);
          }
        }
      });
    }

    function handleClick(tab) {
      activeName.value = tab.props.name;
      getDetail(id.value, activeName.value);
    }
    function openDialog(sendRow) {
      getAllHotelData();
      getAllScenicData();
      getAllDinnerData();
      getAllTicketData();
      getAllActiveData();
      activeName.value = "1";
      id.value = sendRow.mp_id;
      getDetail(id.value, "1");

      setTimeout(() => {
        dialogRef.value.show();
      }, 600);
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    const fullLoading = ref(false);
    function setMiniConfig(data, row, type, isSelect) {
      const lastParmas = {
        mpc_mp_id: id.value,
        mpc_img: row.mpc_img,
        mpc_type: activeName.value,
        mpc_column: type,
        mpc_score: row.mpc_score,
        mpc_sales_num: row.mpc_sales_num,
      };
      row.mpc_id ? (lastParmas["mpc_id"] = row.mpc_id) : "";
      if (activeName.value == 1) {
        if (type == 1) {
          // 首页-顶部banner图
          lastParmas["mpc_third_type"] = isSelect
            ? data[0]
            : row.mpc_third_id.length
            ? row.mpc_third_id[0]
            : row.mpc_third_type;
          lastParmas["mpc_third_id"] = isSelect
            ? data[1]
            : row.mpc_third_id.length
            ? row.mpc_third_id[1]
            : row.mpc_third_id;
        } else if (type == 3) {
          // 首页-门票
          lastParmas["mpc_third_type"] = 6;
          lastParmas["mpc_third_id"] = isSelect ? data : row.mpc_third_id;
        } else if (type == 2) {
          //首页-活动
          lastParmas["mpc_third_type"] = 5;
          lastParmas["mpc_third_id"] = isSelect ? data : row.mpc_third_id;
        }
      } else if (activeName.value == 2) {
        if (type == 2) {
          // 餐厅-活动
          lastParmas["mpc_third_type"] = 5;
          lastParmas["mpc_third_id"] = isSelect ? data : row.mpc_third_id;
        } else {
          // 餐厅-顶部banner栏
          lastParmas["mpc_third_type"] = 3;
          lastParmas["mpc_third_id"] = isSelect ? data : row.mpc_third_id;
        }
      } else if (activeName.value == 3) {
        if (type == 2) {
          // 景区-活动
          lastParmas["mpc_third_type"] = 5;
          lastParmas["mpc_third_id"] = isSelect ? data : row.mpc_third_id;
        } else {
          // 景区-顶部banner栏
          lastParmas["mpc_third_type"] = 2;
          lastParmas["mpc_third_id"] = isSelect ? data : row.mpc_third_id;
        }
      }
      BasicApi.setMiniConfig(lastParmas).then((res) => {
        if (res.Code === 200) {
          getDetail(id.value, activeName.value);
        } else {
          let msg = res.Message ? res.Message : "操作失败！";
          ElMessage.error(msg);
        }
      });
    }
    function handleSelect(data, row, type) {
      if (row.mpc_img) {
        setMiniConfig(data, row, type, 1);
      }
    }
    function uploadFile(data, row, type) {
      // 1banner 2活动 3门票
      if (!row.mpc_third_id) {
        ElMessage.warning("请先在左侧选择所要关联的活动或商品！");
        return false;
      }
      fullLoading.value = true;
      row.file_url = data.standard_url;
      row.mpc_img = data.key;
      setMiniConfig(data, row, type, 0);
    }
    /**
     * 
     * 填写评分、份数事件
     * 
     * **/
    function inputBoxBlur(data, row, type) {
      if (row.mpc_img) {
        setMiniConfig(data, row, type, 0);
      }
    }
    /**
     * 
     * 删除按钮
     * 
     * **/
    function onDeleteBtn(data) {
      if(!data.mpc_id) return false;
      ElMessageBox.confirm("确认删除该内容吗?", "温馨提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      })
        .then(() => {
          BasicApi.delMiniConfig({mpc_id: data.mpc_id}).then((res) => {
            if (res.Code === 200) {
              getDetail(id.value, activeName.value);
            } else {
              let msg = res.Message ? res.Message : "操作失败！";
              ElMessage.error(msg);
            }
          });
        })
        .catch(() => {});
    }

    return {
      openDialog,
      dialogRef,
      closeDialog,
      activeName,
      handleClick,
      uploadFile,
      fullLoading,
      bannerArr,
      cascaderOptions,
      ticketArr,
      activityArr,
      selectOptions,
      allTicketData,
      handleSelect,
      allActiveData,
      onDeleteBtn,
      inputBoxBlur,
    };
  },
};
</script>

<style lang="scss">
.config-detail-dialog {
  .config-detail-main {
    .el-tabs__header {
      margin: 0;
    }
    .el-input-group__append, .el-input-group__prepend {
      color: var(--text-color);
      background-color: var(--search-uncheck-bg-color);
      border: none;
    }
    .el-tabs__nav {
      border-color: transparent !important;
      .el-tabs__item {
        background-color: var(--search-bg-color);
        color: var(--text-third-color);
        border-color: transparent !important;
        margin-right: 2px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        user-select: none;
      }
      .is-active {
        background-color: var(--theme-color);
        color: #fff;
      }
    }

    .config-imgs {
      display: flex;
      height: 660px;
      .config-img-item {
        display: flex;
        align-items: flex-start;
        position: relative;
        width: 100%;
        .el-cascader,
        .el-select {
          width: 310px;
        }
        .config-img-tips {
          color: var(--text-third-color);
          font-size: 12px;
          position: absolute;
          bottom: 30px;
          left: 0;
        }
        .img-upload {
          margin-left: 50px;
        }
        .first-img-item {
          margin-bottom: 26px;
        }
        .other-img-item {
          margin-bottom: 10px;
        }
        .delete-btn {
          padding: 0;
          width: 50px;
          margin-left: 10px;
          background-color: #fff;
          border: none;color: var(--error-color);
        }
      }
      .tickets {
        margin-bottom: 70px;
      }
      p {
        color: var(--text-color);
        font-weight: bolder;
        margin: 16px 0 6px 0;
      }
      .config-imgs-left {
        overflow-y: auto;
        padding: 0 10px 10px 0;
        box-sizing: border-box;
        width: 50%;
        border-right: 1px solid var(--search-bg-color);
        .ticket {
          border-top: 1px solid var(--search-bg-color);
        }
      }
      .config-imgs-right {
        width: 50%;
        padding: 0 10px 10px 25px;
        box-sizing: border-box;
      }
    }
  }
  .el-dialog__footer {
    padding-top: 5px;
  }
}
</style>
